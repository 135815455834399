<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" >
          <v-banner
            color="dark"
            icon="mdi-qrcode-scan"
            single-line
          >
          Ingresa los parámetros para crear el QR que mande a Whatsapp
          </v-banner>
        </v-col>
        <v-col md="6" cols="12" >
          
          <div v-if="ruta" class="d-flex flex-column align-center">
            <v-alert
              border="left"
              colored-border
              type="success"
              elevation="2"
            >
              Se ha generado correctamente el QR. <br>
              <strong>Enlace generado: </strong> {{ruta}}
            </v-alert>
            <qrcode-vue :value="ruta" :size="pixeles" level="Q" ></qrcode-vue>
          </div>
          <div v-else class="d-flex flex-column align-center">
            <v-alert
              border="left"
              colored-border
              type="info"
              elevation="2"
            >
              Este solo es un ejemplo de visualización del QR, para generarlo llena los parametros del lado derecho y haz clic en Generar QR
            </v-alert>
            <v-img
              
              :lazy-src="'https://api.qrserver.com/v1/create-qr-code/?data='+ruta+'&size='+'100x100'"
              :max-height="pixeles"
              :max-width="pixeles"
              :src="'https://api.qrserver.com/v1/create-qr-code/?size='+pixeles+'x'+pixeles+'&data='+ruta"
              :href="'https://api.qrserver.com/v1/create-qr-code/?size='+pixeles+'x'+pixeles+'&data='+ruta" :download="frase+'.png'" target="new"
            ></v-img>
          </div>
          
        </v-col>
        <v-col >
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="numero"
                  label="Numero de télefono"
                  placeholder="Placeholder"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="frase"
                  label="Palabra/Frase"
                  placeholder="RESULTADOS"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="origen"
                  label="¿Donde se pondrá el QR?"
                  placeholder="FACEBOOK"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="pixeles"
                  label="Tamaño de QR (en pixeles)"
                  placeholder="Pixeles"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn
                  elevation="2"
                  outlined
                  @click="generarRuta"
                  color="success"
                >Generar QR
                  <v-icon
                    right
                    dark
                  >
                    mdi-qrcode-edit
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        
          
      </v-row>
    </v-container>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
  export default {
    name:'GeneradorQRs',
    data: () => ({
      frase: null,
      origen:null,
      numero:'525539566729',
      pixeles:250,
      ruta:null
    }),
    components: {
      QrcodeVue,
    },
    methods:{
      generarRuta(){
        let fraseMod= this.frase.replaceAll(' ','%20')
        this.ruta='https://api.whatsapp.com/send/?phone='+this.numero+'&text='+fraseMod+'&utm_source='+this.origen
      },
      descargar(){
        window.location.href= 'https://api.qrserver.com/v1/create-qr-code/?size='+this.pixeles+'x'+this.pixeles+'&data='+this.ruta
      }
    }
  }
</script>